<template>
    <div>
    <h1>Leaderboard</h1>

    <div v-for="user in leaderboard" :key="user.name">
        <div>
        <p>{{ user.name }} fick tiden {{ user.tid }}</p>
    </div>
    </div></div>
</template>
<script>
import axios from 'axios'
export default {
  data: () => ({
    leaderboard: [{ name: 'nils', tid: 3 }]
  }),
  created () {
    // Simple GET request using fetch with async/await
    axios.get('/api/futftype/leaderboard').then(response => {
      const data = response.data
      this.leaderboard = data
    })
  }
}

</script>
